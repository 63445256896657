<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 240 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              business_center
            </v-icon>

            Account Type
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card min-width="240">
        <v-card-title class="d-md-none">
          Account Type
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                business_center
              </v-icon>

              Select multiple values
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>
                {{ tooltipText }}
              </span>
            </v-tooltip>
          </div>

          <v-checkbox
            v-for="(item, index) in options"
            :key="index"
            v-model="form.selectedValues"
            :label="item.label"
            :value="item.value"
            @change="submitForm"
            hide-details
            class="mb-0"
          ></v-checkbox>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// used throughout the code
const filterName = "accountType"

const originalForm = () => ({
  selectedValues: []
})

export default {
  name: "FilterAccountType",

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    options: [
      {
        label: "Regular",
        value: 1,
      },
      {
        label: "Business",
        value: 2,
      },
      {
        label: "Creator",
        value: 3,
      }
    ],

    tooltipText: "Identify influencers by their instagram account type - Business, Creator or regular (non business) Instagram account"
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters["influencerDiscovery/findFilterByType"](filterName))
    }
  },

  methods: {
    /**
     * Takes an item and generates the label for it
     */
    computeFilterText({ label }) {
      return `Account Type: ${label}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     */
    submitForm() {
      // if there's some value, then update the filter
      this.$store.dispatch("influencerDiscovery/replaceFilter", {
        type: filterName,
        data: {
          color: "pink lighten-5",
          icon: "business_center",
          iconColor: "pink",
          text: this.computeFilterText({
            label: this.options.filter((search) =>
              this.form.selectedValues
                .includes(search.value))
                .map((_) => _.label)
                .join(", ")
          }),
          inputs: {
            value: this.form.selectedValues
          }
        }
      })
    },
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form = originalForm()
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      this.form.selectedValues = e.detail.item.data.inputs.value
    })
  }
}
</script>
